<template>
    <div>
        <div style="margin-left: 20%; min-height: calc(100vh - 230px);">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" >
                <el-form-item label="原密码" prop="oldpwd">
                    <el-input v-model="ruleForm.oldpwd" placeholder="请输入原密码" class="pwd" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newpwd">
                    <el-input v-model="ruleForm.newpwd" placeholder="请输入新密码" class="pwd" show-password></el-input>
                </el-form-item>
                <el-form-item label="重复新密码" prop="againpwd">
                    <el-input v-model="ruleForm.againpwd" placeholder="请再次输入新密码" class="pwd" show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm()">立即修改</el-button>
                    <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { updatePwd,checkToken } from "@/request/requests";
    import {MessageSuccess,MessageError,MessageWarning,OpenLoading} from "@/util/util"
    export default {
        name: "updatePwd",
        data(){
            let validatePsw = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.ruleForm.newpwd) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return{
                ruleForm:{
                    oldpwd:"",
                    newpwd:"",
                    againpwd:"",
                },
                rules:{
                    oldpwd: [
                        { required: true, message: '请输入原密码', trigger: 'blur' }
                    ],
                    newpwd: [
                        { required: true, message: '请输入新密码', trigger: 'blur' }
                    ],
                    againpwd: [
                        { required: true, message: '请再次输入新密码', trigger: 'blur' },
                        { validator: validatePsw, trigger: 'blur' }
                    ],
                }
            }
        },
        methods:{
            submitForm(){
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let data = {
                            oldpwd: this.ruleForm.oldpwd,
                            newpwd: this.ruleForm.newpwd,
                        }
                        this.updatePwd(data)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async updatePwd(data) {
                let loading=OpenLoading()
                let res = await updatePwd(data)
                if (res.status == "200") {
                    MessageSuccess("修改成功,两秒后跳转到首页")
                    setTimeout(() => {
                        loading.close()
                        this.$router.push({ path: "/backhome" });
                    }, 2000)
                } else {
                    MessageError(res.msg)
                    loading.close()
                }
            },
            resetForm(){
               this.ruleForm={
                    oldpwd:"",
                    newpwd:"",
                    againpwd:"",
                }
            },
            getUserInfo(){
                let deadline = sessionStorage.getItem('deadline');
                let userInfo = sessionStorage.getItem("userInfo");
                let token = sessionStorage.getItem('token');
                if(!deadline||!userInfo||!token) {
                    this.removeInfo()
                    MessageWarning("请先登录！")
                    this.$router.push({ path: "/backhome" });
                    return false
                }else{
                    let now = new Date().getTime();
                    if (deadline<now){
                        this.removeInfo()
                        MessageWarning("请先登录！")
                        this.$router.push({ path: "/backhome" });
                        return false
                    }
                }

                return userInfo;
            },
            async checkTokenInfo(userInfo) {
                let flag=false
                if (userInfo){
                    let token = sessionStorage.getItem('token');
                    let data = {
                        token: token
                    }
                    let res = await checkToken(data)
                    if (res.status != "200") {
                        this.removeInfo()
                        MessageWarning("请先登录！")
                        this.$router.push({ path: "/backhome" });
                    }else{
                        flag=true
                    }
                }
                return flag
            },
            removeInfo(){
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("deadline");
                sessionStorage.removeItem("token");
            },
        },
        created() {
            let userInfo=this.getUserInfo()
            if (userInfo) {
                this.checkTokenInfo(userInfo)
            }
        }
    }
</script>

<style scoped>
    .pwd{
        width: 300px;
    }
</style>
